import * as React from 'react';
import { graphql } from 'gatsby';
import Seo from '../components/Seo/Seo';
import { Layout } from '../components/Layout';
import { Banner, Hero, Services } from '../components';


const IndexPage = ({data}) => {
  return (
    <Layout>
      <Seo title="Strona główna" />
      <Banner />
      <Hero />
      <Services data={data}/>
    </Layout>
  )
}

export const pageQuery = graphql`
query Pastiles {
  allMdx {
    nodes {
      frontmatter {
        content
        offer
        slug
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      slug
    }
  }
}
`

export default IndexPage;